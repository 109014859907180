import React, { Component } from 'react';
import './App.scss';

class App extends Component {
  componentDidMount () {
    // var canvas = document.getElementById("canvas");
    //   if (canvas) {
    //     var context = canvas.getContext("2d");

    //     this.drawStar(context, 40, 50, 100);
    //     this.drawStar(context, 20, 130, 30);
    //     this.drawStar(context, 20, 150, 100);
    //     this.drawStar(context, 20, 150, 150);
    //     this.drawStar(context, 20, 150, 200);
    //   } else {
    //     document.writeln("浏览器不支持canvas组件");
    //   }
  }
  drawStar =  (context, r, x, y) => {
    context.lineWidth = 10;
    context.beginPath();
    var dit = Math.PI * 4 / 5;
    var sin = Math.sin(0) * r + y;
    var cos = Math.cos(0) * r + x;
    context.moveTo(cos, sin);
    for (var i = 0; i < 5; i++) {
      var tempDit = dit * i;
      sin = Math.sin(tempDit) * r + y;
      cos = Math.cos(tempDit) * r + x;
      context.lineTo(cos, sin);
      console.log(sin+":"+sin+":"+tempDit);
    }
    context.closePath();
    context.strokeStyle = "red";
    context.fillStyle = "#fff143";
    context.fill();
  }
  render () {
    return (
      <div className="App">
        <div className="flag">
        <svg t="1569826342509" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1120" width="50" height="50"><path d="M231.866858 924.559197l99.623975-315.315432L76.893274 391.534348l320.43094 0L512.050142 98.1586l128.641869 293.375747 306.414715 0L706.971553 609.097432 809.674657 925.850609 513.64343 727.408001 231.866858 924.559197z" p-id="1121" fill="#f4ea2a"></path></svg>
        <svg t="1569826342509" className="icon1" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1120" width="30" height="30"><path d="M231.866858 924.559197l99.623975-315.315432L76.893274 391.534348l320.43094 0L512.050142 98.1586l128.641869 293.375747 306.414715 0L706.971553 609.097432 809.674657 925.850609 513.64343 727.408001 231.866858 924.559197z" p-id="1121" fill="#f4ea2a"></path></svg>
        <svg t="1569826342509" className="icon2" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1120" width="30" height="30"><path d="M231.866858 924.559197l99.623975-315.315432L76.893274 391.534348l320.43094 0L512.050142 98.1586l128.641869 293.375747 306.414715 0L706.971553 609.097432 809.674657 925.850609 513.64343 727.408001 231.866858 924.559197z" p-id="1121" fill="#f4ea2a"></path></svg>
        <svg t="1569826342509" className="icon3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1120" width="30" height="30"><path d="M231.866858 924.559197l99.623975-315.315432L76.893274 391.534348l320.43094 0L512.050142 98.1586l128.641869 293.375747 306.414715 0L706.971553 609.097432 809.674657 925.850609 513.64343 727.408001 231.866858 924.559197z" p-id="1121" fill="#f4ea2a"></path></svg>
        <svg t="1569826342509" className="icon4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1120" width="30" height="30"><path d="M231.866858 924.559197l99.623975-315.315432L76.893274 391.534348l320.43094 0L512.050142 98.1586l128.641869 293.375747 306.414715 0L706.971553 609.097432 809.674657 925.850609 513.64343 727.408001 231.866858 924.559197z" p-id="1121" fill="#f4ea2a"></path></svg>
        </div>
      </div>
    );
  }
}
export default App;
